import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import {
    MySironaServiceProxy,
    UserContactDto,
    GetEmploymentsOutput,
    UserEmploymentDto,
    IdentificationStatusDto,
    GeneralDocumentGroupDto,
    UserPersonLicenceDto,
    MySironaLegitimationServiceProxy,
    GetLegitimationDto,
    GetLegitimationsOutput
} from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConsts } from '@shared/AppConsts';

@Injectable({
    providedIn: 'root',
})
export class MySironaLegitimationService extends AppComponentBase {
    id: number;
    personId: number;
    sponsorshipId: number;
    legitimations: GetLegitimationDto[] = [];

    constructor(
        injector: Injector,
        private mySironaLegitimationService: MySironaLegitimationServiceProxy,
        private mySironaService: MySironaServiceProxy
    ) {
        super(injector);
    }

    public SetId(id: number) {
        this.id = id;
    }

    public GetId() {
        return this.id;
    }

    public IsLegitimated(id: number): Observable<void> {
        return this.mySironaLegitimationService.isLegitimated(id);
    }

    public SetPersonId(id: number) {
        this.personId = id;
    }

    public GetPersonId() {
        return this.personId;
    }

    public SetSponsorshipId(id: number) {
        this.sponsorshipId = id;
    }

    public GetSponsorshipId() {
        return this.sponsorshipId;
    }

    public get mySironaBasePath(): string {
        return `${AppConsts.applicantStartUrl}/`;
    }


    GetLegitimations(): Observable<GetLegitimationDto[]> {
        return this.mySironaLegitimationService.getLegitimations()
            .pipe(tap((output: GetLegitimationsOutput) => {
                this.legitimations = output.items;
            }), map((output: GetLegitimationsOutput) => output.items));
    };

    IsLegitimationOwnerExisting(uid: string): Observable<boolean>{
        return this.mySironaLegitimationService.isLegitimationOwnerExisting(uid);
    }

}
