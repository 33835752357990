import { Injectable } from '@angular/core';
import { UidRegisterServiceProxy, UidEntityDto, EnumUidStatus } from '@shared/service-proxies/service-proxies';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class UidService {

    constructor(
        private uidService: UidRegisterServiceProxy,
    ) {}

    public getUidEntityByUidNumber(uid: string): Observable<UidEntityDto | undefined> {
        return this.uidService.getUidEntityByUidNumberOrNull(uid);
    }

    public simplifyState(status: EnumUidStatus): string {
        switch (status) {
            case EnumUidStatus.Definitiv:
            case EnumUidStatus.InMutation:
                return 'SimpleUidStatusActive';
            default:
                return 'SimpleUidStatusInactive';
        }
    }

}
