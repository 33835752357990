import {
    CreateOrUpdateLiaAcademicTitleInput, CreateOrUpdateLiaAcademicTitleOutput, CreateOrUpdateLiaDiplomaInput, LiaAcademicTitleDto, LiaAcademicTitleServiceProxy,
    LiaDiplomaDto, LiaDiplomaServiceProxy, LiaEmploymentOutput, LiaRestrictionOutput, LiaEmploymentBDto, LiaEmploymentCDto, CreateOrUpdateLiaEmploymentBInput,
    CreateOrUpdateLiaEmploymentCInput, LiaEmploymentBServiceProxy, LiaGuaranteeServiceProxy, LiaPostgraduateTitlePrivateLawServiceProxy, LiaPostgraduateTitlePrivateLawDto,
    CreateOrUpdateLiaPostgraduateTitlePrivateLawInput, CreateOrUpdateLiaPostgraduateTitlePrivateLawOutput, LiaPostgraduateTitleServiceProxy, LiaPostgraduateTitleDto,
    CreateOrUpdateLiaPostgraduateTitleInput, CreateOrUpdateLiaPostgraduateTitleOutput, LiaRestrictionBServiceProxy, CreateOrUpdateLiaRestrictionBInput, LiaRestrictionBDto,
    LiaPreviousEmploymentServiceProxy, LiaPreviousEmploymentDto, CreateOrUpdateLiaPreviousEmploymentInput, LiaEmploymentBRecordDto, LiaEmploymentCRecordDto, LiaLanguageSkillBServiceProxy,
    CreateOrUpdateLiaLanguageSkillBInput, LiaLanguageSkillBDto, LiaLicenceInAnotherCantonServiceProxy, CreateOrUpdateLiaLicenceInAnotherCantonInput, LiaLicenceInAnotherCantonCacheDto,
    LiaLicenceInAnotherCantonDto, CreateOrUpdateLiaPersonExtendedInput, CreateOrUpdateLiaEmploymentsOutput, CreateOrUpdateLiaPersonExtendedOutput, GetLiaPersonLicenceDto,
    LiaPersonServiceProxy, LiaPersonDto, GetLiaOutput, LiaStatus, LiaContactDto, CreateOrUpdateLiaContactInput,
    LiaContactServiceProxy, LiaEmploymentDto, CreateOrUpdateLiaRestrictionsInput, LiaRestrictionsServiceProxy, LiaInsuranceServiceProxy, LiaEmploymentsServiceProxy,
    LiaInvoiceAddressServiceProxy, LiaDocumentServiceProxy, LiaInsuranceDto, CreateOrUpdateLiaInsuranceInput, CreateOrUpdateLiaEmploymentsInput, LiaInvoiceAddressDto, CreateOrUpdateLiaInvoiceAddressInput,
    ApplicationUploadFilesInput, LiaDocumentsOutput, LiaTypeServiceProxy, CreateLiaTypeInput, CreateLiaTypeOutput, LiaDto,  ApplicationServiceProxy,
    GeneralFileInfoDto, GeneralFileDto, GeneralDocumentGroupDto, CreateOrUpdateLiaPersonOutputDto, LiaLanguageSkillServiceProxy, CreateOrUpdateLiaLanguageSkillInput,
    LiaLanguageSkillDto, CreateOrUpdateLiaPersonWithRegisterCheckInput, ExternalSystemEnum, ProfessionRequirementType, LiaEmploymentCServiceProxy,
    LiaVaccinationServiceProxy, LiaVaccinationDto, CreateOrUpdateLiaVaccinationInputDto, CreateOrUpdateLiaEmploymentsInWaitingStatusOutput, SetLiaOnWaitingAndCreateEhcOutput, LiaEmploymentReadOnlyDto, CreateOrUpdateLiaEmploymentCInWaitingStatusOutput, CreateOrUpdateLiaEmploymentCOutput,
    ApplicationStatus
} from './../../../shared/service-proxies/service-proxies';
import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, map, delay, switchMap } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { AppConsts } from '@shared/AppConsts';
import { ApplicationService } from '@app/shared/application/application.service';
import { CodeService } from '@app/shared/common/code/code.service';
import { ProfessionConfigurationService } from '@app/shared/services/profession-configuration.service';

export class LiaInvoiceAddressDetail {
    name!: string | undefined;
    street!: string;
    streetNr!: string | undefined;
    postCode!: string;
    city!: string;
    email!: string;
    countryId!: string | undefined;

    constructor(data?: LiaInvoiceAddressDetail) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}

@Injectable()
export class LiaService extends ApplicationService {

    lia: LiaDto;
    liaResultDocuments: GeneralFileInfoDto[];
    onlyPrivateAddressAllowed = false;
    notConsiderMessage: string;

    constructor(
        injector: Injector,
        private appService: ApplicationServiceProxy,
        codeService: CodeService,
        private professionConfigurationService: ProfessionConfigurationService,
        private liaTypeService: LiaTypeServiceProxy,
        private liaPersonService: LiaPersonServiceProxy,
        private liaContactService: LiaContactServiceProxy,
        private liaRestrictionService: LiaRestrictionsServiceProxy,
        private liaRestrictionBService: LiaRestrictionBServiceProxy,
        private liaInsuranceService: LiaInsuranceServiceProxy,
        private liaEmploymentsService: LiaEmploymentsServiceProxy,
        private liaEmploymentCService: LiaEmploymentCServiceProxy,
        private liaEmploymentBService: LiaEmploymentBServiceProxy,
        private liaPreviousEmploymentService: LiaPreviousEmploymentServiceProxy,
        private liaLanguageSkillService: LiaLanguageSkillServiceProxy,
        private liaLanguageSkillBService: LiaLanguageSkillBServiceProxy,
        private liaInvoiceAddressService: LiaInvoiceAddressServiceProxy,
        private liaDocumentService: LiaDocumentServiceProxy,
        private liaDiplomaService: LiaDiplomaServiceProxy,
        private liaAcademicTitleService: LiaAcademicTitleServiceProxy,
        private liaGuaranteeService: LiaGuaranteeServiceProxy,
        private liaPostgraduateTitlePrivateLawService: LiaPostgraduateTitlePrivateLawServiceProxy,
        private liaPostgraduateTitleService: LiaPostgraduateTitleServiceProxy,
        private liaLicenceInAnotherCantonService: LiaLicenceInAnotherCantonServiceProxy,
        private liaVaccinationServiceProxy: LiaVaccinationServiceProxy
    ) {
        super(injector, codeService, appService);
    }

    get currentStep(): LiaStatus {
        return this.lia.currentStep;
    }

    getExternalSystemByProfessionId(professionId: string): Observable<ExternalSystemEnum> {
        return this.liaTypeService.getExternalRegisterByProfessionId(professionId);
    }

    getLiaForProfessionAndGln(caseId: string): Observable<GetLiaOutput> {
        return this.liaTypeService.getLiaForProfessionAndGln(caseId);
    }

    getActiveProfessions(): Observable<string[]> {
        return this.liaTypeService.getActiveProfessions();
    }

    isExtendedLiaPersonEnabled(): Observable<boolean> {
        return this.liaTypeService.isExtendedLiaPersonEnabled(this.profession);
    }

    createLia(input: CreateLiaTypeInput): Observable<CreateLiaTypeOutput> {
        return this.liaTypeService.createLiaType(input).pipe(tap((output: CreateLiaTypeOutput) => {
            this.lia = null;
        }));
    }

    deleteLia(): Observable<void> {
        return this.appService.deleteApplication(this.lia.application.caseId);
    }

    getLia(caseId: string): Observable<GetLiaOutput> {
        this.lia = null;
        return this.getApplication(caseId).pipe(switchMap(() => {
            return this.liaTypeService.getLia(caseId).pipe(tap(
                (output: GetLiaOutput) => {
                    this.lia = output.lia;

                    return this.professionConfigurationService.getRequirementByProfessionAndType(this.lia.professionId, ProfessionRequirementType.OnlyPrivateAddressAllowed).subscribe(result => {
                        this.onlyPrivateAddressAllowed = result.value == this.codes.yesNo.yes;
                    });
            }));
        }));
    }

    getLastSupplementalClaim(): Observable<string> {
        return this.liaTypeService.getLastSupplementalClaim(this._caseId);
    }

    getMyLias(): Observable<LiaDto[]> {
        return this.liaTypeService.getMyLias();
    }

    getLiasForUser(id: number): Observable<LiaDto[]> {
        return this.liaTypeService.getLiasForUser(id);
    }

    isInLiaStatus(expectedStatus: LiaStatus): boolean {
        return this.lia.currentStep === expectedStatus;
    }

    getEvaluationComments(): Observable<string> {
        return this.liaTypeService.getEvaluationComments(this._caseId);
    }

    get caseId(): string {
        return this.lia.application.caseId;
    }

    get profession(): string {
        return this.lia.professionId;
    }

    get numberOfLinkedEhcApplications(): number {
        return this.lia.numberOfLinkedEhcApplications;
    }

    get hasAnyLinkedEhcUnderConstruction(): boolean {
        const statuses = this.lia?.statusOfLinkedApplications;
        return statuses?.some(x => x == ApplicationStatus.UnderConstruction);
    }

    getPerson(): Observable<LiaPersonDto> {
        return of(this.lia.liaPerson).pipe(delay(1));
    }

    setLiaPersonStepToReview() {
        this.setStepToReview(LiaStatus.Person);
    }

    createOrUpdateLiaPersonWithRegisterCheck(input: CreateOrUpdateLiaPersonWithRegisterCheckInput): Observable<CreateOrUpdateLiaPersonOutputDto> {
        this.setStepToReview(LiaStatus.Person);
        return this.liaPersonService.createOrUpdateLiaPersonWithRegisterCheck(input).pipe(tap((output: CreateOrUpdateLiaPersonOutputDto) => {
            this.lia.liaPerson = output.liaPerson;
        }));
    }

    createOrUpdateLiaPersonExtended(input: CreateOrUpdateLiaPersonExtendedInput): Observable<CreateOrUpdateLiaPersonExtendedOutput> {
        this.setStepToReview(LiaStatus.Person);
        return this.liaPersonService.createOrUpdateLiaPersonExtended(input).pipe(tap((output: CreateOrUpdateLiaPersonExtendedOutput) => {
            this.lia.liaPerson = output.liaPerson;
            this.lia.currentStep = output.status;
        }));
    }

    acceptRegisterCheck(): Observable<LiaStatus> {
        this.setStepToReview(LiaStatus.Person);
        return this.liaPersonService.acceptRegisterCheck(this.caseId).pipe(tap((status: LiaStatus) => {
            this.lia.currentStep = status;
        }));
    }


    get medRegId(): number {
        return this.lia.liaPerson.medRegId;
    }

    get hasMedRegId(): boolean {
        return this.lia.liaPerson.medRegId != null;
    }

    get psyRegId(): number {
        return this.lia.liaPerson.psyRegId;
    }

    get hasPsyRegId(): boolean {
        return this.lia.liaPerson.psyRegId != null;
    }

    get naregId(): number {
        return this.lia.liaPerson.naregId;
    }

    get hasNaregId(): boolean {
        return this.lia.liaPerson.naregId != null;
    }

    get hasLicenceInOtherCanton(): boolean {
        return this.lia.liaPerson.hasLicenceInAnotherCantonId == this.codes.yesNo.yes;
    }

    getContact(): Observable<LiaContactDto> {
        return of(this.lia.liaContact).pipe(delay(0));
    }

    createOrUpdateLiaContact(input: CreateOrUpdateLiaContactInput): Observable<void> {
        this.setStepToReview(LiaStatus.Contact);
        return this.liaContactService.createOrUpdateLiaContact(input).pipe(tap((status: LiaStatus) => {
            this.lia.liaContact = input.liaContact;
            this.lia.currentStep = status;
        }))
            .pipe(map((status: LiaStatus) => null));
    }

    updateLiaContactInWaitingStatus(input: CreateOrUpdateLiaContactInput): Observable<void> {
        return this.liaContactService.updateLiaContactInWaitingStatus(input).pipe(tap((ehcCaseId: string) => {
           this.router.navigate([`${AppConsts.applicantBaseUrl}/ehc/${ehcCaseId}/wizard/contact`]);
        })).pipe(map(() => null));
    }

    getRestrictions(): Observable<LiaRestrictionOutput> {
        return of(this.lia.liaRestrictions).pipe(delay(1));
    }

    createOrUpdateLiaRestrictions(input: CreateOrUpdateLiaRestrictionsInput, hasImpairments: boolean): Observable<void> {
        this.setStepToReview(LiaStatus.Restriction);
        return this.liaRestrictionService.createOrUpdateLiaRestrictions(input).pipe(tap((status: LiaStatus) => {
            this.lia.liaRestrictions.restrictions = input.liaRestrictions;
            this.lia.currentStep = status;
            this.lia.liaRestrictions.hasAnyImpairmentRelatedToPracticeId = hasImpairments ? this.codes.yesNo.yes : this.codes.yesNo.no;
        }))
            .pipe(map((status: LiaStatus) => null));
    }

    getLanguageSkill(): Observable<LiaLanguageSkillDto> {
        return of(this.lia.liaLanguageSkill).pipe(delay(1));
    }

    createOrUpdateLiaLanguageSkill(input: CreateOrUpdateLiaLanguageSkillInput): Observable<void> {
        this.setStepToReview(LiaStatus.LanguageSkill);
        return this.liaLanguageSkillService.createOrUpdateLiaLanguageSkill(input).pipe(tap((status: LiaStatus) => {
            this.lia.liaLanguageSkill = input.liaLanguageSkill;
            this.lia.currentStep = status;
        })).pipe(map((status: LiaStatus) => null));
    }

    getInsurance(): Observable<LiaInsuranceDto> {
        return of(this.lia.liaInsurance).pipe(delay(1));
    }

    updateGlnIfMedRegId(): Observable<string> {
        return this.liaPersonService.updateGlnIfMedRegId(this._caseId).pipe(tap((result: string) => {
            if (result) {
                this.lia.liaPerson.gln = result;
            }
        }));
    }

    createOrUpdateLiaInsurance(input: CreateOrUpdateLiaInsuranceInput): Observable<void> {
        this.setStepToReview(LiaStatus.Insurance);
        return this.liaInsuranceService.createOrUpdateLiaInsurance(input).pipe(tap((status: LiaStatus) => {
            this.lia.liaInsurance = input.liaInsurance;
            this.lia.currentStep = status;
        }))
            .pipe(map((status: LiaStatus) => null));
    }

    getEmployments(): Observable<LiaEmploymentOutput> {
        return of(this.lia.liaEmployments).pipe(delay(1));
    }

    hasAtLeastOneEmployment(): boolean {
        if (this.lia.liaEmployments?.employments?.length > 0 || this.lia.liaEmployments?.mobileEmployment?.hasMobileEmployment) {
            return true;
        }

        if (this.lia.liaEmploymentB?.employmentRecords?.length > 0 || this.lia.liaEmploymentB?.hasMobileEmployment) {
            return true;
        }

        if (this.lia.liaEmploymentC?.employmentRecords?.length > 0 || this.lia.liaEmploymentC?.mobileEmployment?.hasMobileEmployment) {
            return true;
        }

        return false;
    }

    createOrUpdateLiaEmployments(input: CreateOrUpdateLiaEmploymentsInput): Observable<void> {
        this.setStepToReview(LiaStatus.Employment);
        return this.liaEmploymentsService.createOrUpdateLiaEmployments(input).pipe(tap((output: CreateOrUpdateLiaEmploymentsOutput) => {
            this.lia.liaEmployments.employments = output.liaEmployments;
            this.lia.liaEmployments.mobileEmployment = output.liaMobileEmployment;
            this.lia.currentStep = output.status;
        }))
            .pipe(map((output: CreateOrUpdateLiaEmploymentsOutput) => null));
    }

    createOrUpdateLiaEmploymentsInWaitingStatus(input: CreateOrUpdateLiaEmploymentsInput): Observable<void> {
        return this.liaEmploymentsService.createOrUpdateLiaEmploymentsWhileWaitingStatus(input).pipe(tap((output: CreateOrUpdateLiaEmploymentsInWaitingStatusOutput) => {
            if (output.isNewInvoiceAddressRequired) {
                this.lia.liaEmployments.employments = output.liaEmployments;
                this.lia.liaEmployments.mobileEmployment = output.liaMobileEmployment;
                this.router.navigate([`${AppConsts.applicantBaseUrl}/lia/${this._caseId}/wizard/invoice-address`]);
            } else {
                this.router.navigate([`${AppConsts.applicantBaseUrl}/ehc/${output.ehcCaseId}/wizard/employment`]);
            }
        })).pipe(map(() => null));
    }

    getInvoiceAddress(): Observable<LiaInvoiceAddressDto> {
        return of(this.lia.liaInvoiceAddress).pipe(delay(1));
    }

    getInvoiceAddressDetail(): Observable<LiaInvoiceAddressDetail> {
        let detail: LiaInvoiceAddressDetail;
        if (this.lia.liaInvoiceAddress.typeOfAddressId == this.codes.address.private) {
            let fullName = this.lia.liaContact.titleId !== null ? this.l(this.lia.liaContact.titleId) + ' ' + this.lia.application.name : this.lia.application.name;
            detail = new LiaInvoiceAddressDetail({
                name: fullName,
                street: this.lia.liaContact.street,
                streetNr: this.lia.liaContact.streetNr,
                postCode: this.lia.liaContact.postCode,
                city: this.lia.liaContact.city,
                email: this.lia.liaContact.email,
                countryId: this.lia.liaContact.countryId,
            });
        }
        else if (this.lia.liaInvoiceAddress.typeOfAddressId == this.codes.address.business) {
            let employment = this.lia.liaEmployments.employments.find(e => e.id == this.lia.liaInvoiceAddress.liaEmploymentId);
            detail = new LiaInvoiceAddressDetail({
                name: employment.businessName,
                street: employment.street,
                streetNr: employment.streetNr,
                postCode: employment.postCode,
                city: employment.city,
                email: employment.email,
                countryId: null,
            });
        }
        else {
            detail = null;
        }
        return of(detail).pipe(delay(0));
    }

    createAddressDropdownList(): SelectItem[] {

        var addresses: SelectItem[] = [];

        addresses.push({ value: AppConsts.codes.address.private, label: this.l('Address-Private') + ': ' + this.getPrivateAddressDescription(this.lia.liaContact) });

        if (!this.onlyPrivateAddressAllowed) {

            for (let i = 0; i < this.lia.liaEmployments?.employments.length; i++) {
                // Use employment id as item value
                addresses.push({ value: this.lia.liaEmployments.employments[i].id, label: this.l('EmploymentSingular') + ': ' + this.getEmploymentAddressDescription(this.lia.liaEmployments.employments[i]) });
            }

            for (let i = 0; i < this.lia.liaEmploymentB?.employmentRecords.length; i++) {
                // Use employmentB id as item value
                addresses.push({ value: this.lia.liaEmploymentB.employmentRecords[i].id, label: this.l('EmploymentSingular') + ': ' + this.getEmploymentAddressDescription(this.lia.liaEmploymentB.employmentRecords[i]) });
            }

            for (let i = 0; i < this.lia.liaEmploymentC?.employmentRecords.length; i++) {
                // Use employmentC id as item value
                addresses.push({ value: this.lia.liaEmploymentC.employmentRecords[i].id, label: this.l('EmploymentSingular') + ': ' + this.getEmploymentAddressDescription(this.lia.liaEmploymentC.employmentRecords[i]) });
            }

            addresses.push({ value: AppConsts.codes.address.invoice, label: this.l(AppConsts.codes.address.invoice) });
        }

        return addresses;
    }

    IsOnlyPrivateAddressAllowed(): boolean {
        return this.onlyPrivateAddressAllowed;
    }

    hasEmploymentB(): boolean {
        return this.lia.liaEmploymentB?.employmentRecords?.length > 0;
    }

    hasEmploymentC(): boolean {
        return this.lia.liaEmploymentC?.employmentRecords?.length > 0;
    }

    getProfessionalLicenceForLia(): Observable<GetLiaPersonLicenceDto> {
        return this.liaTypeService.getProfessionalLicenceForLia(this._caseId);
    }

    private getPrivateAddressDescription(contact: LiaContactDto) {
        let str = contact?.street;

        if (contact?.streetNr && contact.streetNr.length > 0) {
            str = str.concat(' ');
            str = str.concat(contact.streetNr);
        }

        str = str?.concat(', ');
        str = str?.concat(contact?.city);

        return str;
    }

    private getEmploymentAddressDescription(employment: LiaEmploymentDto | LiaEmploymentBRecordDto | LiaEmploymentCRecordDto) {
        let str = employment?.businessName;

        if (employment.street?.length > 0) {
            if (str.length > 0) {
                str = str.concat(', ');
            }

            str = str.concat(employment.street);

            if (employment.streetNr?.length > 0) {
                str = str.concat(' ');
                str = str.concat(employment.streetNr);
            }
        }

        if (employment.city?.length > 0) {
            if (str.length > 0) {
                str = str.concat(', ');
            }

            str = str.concat(employment.city);
        }

        return str;
    }



    createOrUpdateLiaInvoiceAddress(input: CreateOrUpdateLiaInvoiceAddressInput): Observable<void> {
        this.setStepToReview(LiaStatus.InvoiceAddress);
        return this.liaInvoiceAddressService.createOrUpdateLiaInvoiceAddress(input).pipe(tap((status: LiaStatus) => {
            this.lia.liaInvoiceAddress = input.liaInvoiceAddress;
            this.lia.currentStep = status;
        }))
            .pipe(map((status: LiaStatus) => null));
    }

    getDocuments(forceRefresh?: boolean, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.liaDocumentService.getLiaDocuments(caseId).pipe(tap((output: LiaDocumentsOutput) => {
                this.lia.liaDocuments = output.liaDocuments;
            })).pipe(map((output: LiaDocumentsOutput) => output.liaDocuments));
        }
        return of(this.lia.liaDocuments);
    }

    createAddressDropdownListForEhcFromLiaEntities(liaContact: LiaContactDto, liaEmployments: LiaEmploymentReadOnlyDto[], isOnlyPrivateAddressAllowed: boolean): SelectItem[] {

        var addresses: SelectItem[] = [];

        addresses.push({ value: AppConsts.codes.address.private, label: this.l('Address-Private') + ': ' + this.getPrivateAddressDescription(liaContact) });
        if (isOnlyPrivateAddressAllowed) {
            return addresses;
        }

        for (let i = 0; i < liaEmployments.length; i++) {
            if (liaEmployments[i].isMarkedForEhc == true) {
                addresses.push({ value: liaEmployments[i].id ?? liaEmployments[i].guid, label: this.l('EmploymentSingular') + ': ' + this.getEmploymentAddressDescription(liaEmployments[i]) });
            }
        }

        addresses.push({ value: AppConsts.codes.address.invoice, label: this.l(AppConsts.codes.address.invoice) });



        return addresses;
    }

    // Preliminary solution until we know what to use this info for.
    getDocumentTypeInfo(documentType: string): string {
        switch (documentType) {
            case this.codes.documentType.centralCriminalRecordExtract:
                return this.l('DocumentCentralCriminalRecordExtractInfo');
            case this.codes.documentType.curriculumVitae:
                return this.l('DocumentCurriculumVitaeInfo');
            case this.codes.documentType.postgraduatePractiseCertificate:
                return this.l('DocumentPostgraduatePractiseCertificateInfo');
            case this.codes.documentType.medicalCertificate:
                return this.l('DocumentMedicalCertificateInfo');
            case this.codes.documentType.diplomaOfAtLeastThreeYearsOfProfessionalTraining:
                return this.l('DocumentDiplomaOfAtLeastThreeYearsOfProfessionalTraining');
            case this.codes.documentType.proofOfLanguageProficiency:
                return this.l('DocumentProofOfLanguageProficiency');
            case this.codes.documentType.confirmatioOfActivityUnderProfessionalSupervision:
                return this.l('DocumentConfirmatioOfActivityUnderProfessionalSupervision');
            case this.codes.documentType.evidencOfRequiredHours:
                return this.l('DocumenEvidencOfRequiredHours');
            default:
                return '';
        }
    }

    getFile(id: number): Observable<GeneralFileDto> {
        return this.liaDocumentService.getFile(this._caseId, id);
    }

    getLanguageSkillFiles(forceRefresh?: boolean, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.liaLanguageSkillService.getLiaLanguageSkillDocuments(caseId).pipe(tap((output: LiaDocumentsOutput) => {
                this.lia.liaLanguageSkill.languageSkillDocuments = output.liaDocuments;
            })).pipe(map((output: LiaDocumentsOutput) => output.liaDocuments));
        }
        return of(this.lia.liaLanguageSkill.languageSkillDocuments);
    }

    getLanguageSkillFile(fileId: number): Observable<GeneralFileDto> {
        return this.liaLanguageSkillService.getFile(this._caseId, fileId)
    }

    uploadLanguageSkillFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.liaLanguageSkillService.uploadFiles(input);
    }

    deleteLanguageSkillFile(fileId: number): Observable<void> {
        return this.liaLanguageSkillService.deleteFile(this._caseId, fileId);
    }

    getRestrictionFiles(forceRefresh?: boolean, hasRestriction: boolean = false, hasImpairments: boolean = false, caseId = this._caseId, ): Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.liaRestrictionService.getLiaRestrictionDocuments(caseId, hasRestriction, hasImpairments).pipe(tap((output: LiaDocumentsOutput) => {
                this.lia.liaRestrictions.restrictionDocuments = output.liaDocuments;
            })).pipe(map((output: LiaDocumentsOutput) => output.liaDocuments));
        }
        return of(this.lia.liaRestrictions.restrictionDocuments);
    }

    getRestrictionFile(fileId: number): Observable<GeneralFileDto> {
        return this.liaRestrictionService.getFile(this._caseId, fileId)
    }

    uploadRestrictionFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.liaRestrictionService.uploadFiles(input);
    }

    deleteRestrictionFile(fileId: number): Observable<void> {
        return this.liaRestrictionService.deleteFile(this._caseId, fileId);
    }

    getLiaPersonFiles(forceRefresh?: boolean, hasLicenceInAnotherCanton?: boolean, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.liaPersonService.getLiaPersonDocuments(caseId, hasLicenceInAnotherCanton).pipe(tap((output: LiaDocumentsOutput) => {
                this.lia.liaPerson.personDocuments = output.liaDocuments;
            })).pipe(map((output: LiaDocumentsOutput) => output.liaDocuments));
        }
        return of(this.lia.liaPerson.personDocuments);
    }

    getLiaPersonFile(fileId: number): Observable<GeneralFileDto> {
        return this.liaPersonService.getFile(this._caseId, fileId)
    }

    uploadLiaPersonFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.liaPersonService.uploadFiles(input);
    }

    deleteLiaPersonFile(fileId: number): Observable<void> {
        return this.liaPersonService.deleteFile(this._caseId, fileId);
    }

    uploadFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.liaDocumentService.uploadFiles(input);
    }

    deleteFile(id: number): Observable<void> {
        return this.liaDocumentService.deleteFile(this._caseId, id);
    }

    fileUploadCompleted(caseId: string): Observable<void> {
        this.setStepToReview(LiaStatus.Document);
        return this.liaDocumentService.fileUploadCompleted(caseId).pipe(tap((status: LiaStatus) => {
            this.lia.currentStep = status;
        }))
            .pipe(map((status: LiaStatus) => null));
    }

    releaseLia(): Observable<void> {
        return this.liaTypeService.releaseLia(this.lia.application.caseId);
    }

    setLiaOnWaitingAndCreateEhc(): Observable<SetLiaOnWaitingAndCreateEhcOutput> {
        return this.liaTypeService.setLiaOnWaitingAndCreateEhc(this.lia.application.caseId)
    }

    getStepUrl(nextStep: LiaStatus): string {
        return this.getNextStep(nextStep);
    }

    getNextStatus(status: LiaStatus): Observable<LiaStatus> {
        let isAuthority = this.permission.isGranted('Pages.Authority.Applications');
        return this.liaTypeService.getNextStatus(this._caseId, status).pipe(map(status => {
            // Authority goes to 'finish review' and not to 'release'
            if (isAuthority && status == LiaStatus.Release) {
                return LiaStatus.FinishReview;
            }
            return status;
        }));
    }

    getNextStep(nextStep: LiaStatus): string {
        const baseUrl = AppConsts.applicantBaseUrl + `/lia/${this.caseId}/wizard/`;
        const path = this.getUrlForStep(nextStep);
        return baseUrl + path;
    }

    checkDocuments(): Observable<LiaStatus[]> {
        return this.liaTypeService.validateThatAllMandatoryDocumentsHaveBeenUploaded(this.caseId);
    }


    getDiploma(): Observable<LiaDiplomaDto> {
        return of(this.lia.liaDiploma);
    }

    createOrUpdateLiaDiploma(input: CreateOrUpdateLiaDiplomaInput): Observable<void> {
        this.setStepToReview(LiaStatus.Diploma);
        return this.liaDiplomaService.createOrUpdateLiaDiploma(input).pipe(tap((status: LiaStatus) => {
            this.lia.liaDiploma = input.liaDiploma;
            this.lia.currentStep = status;
        })).pipe(map(() => null));
    }

    getAcademicTitles(): Observable<LiaAcademicTitleDto[]> {
        return of(this.lia.liaAcademicTitles.titles);
    }

    createOrUpdateLiaAcademicTitles(input: CreateOrUpdateLiaAcademicTitleInput): Observable<void> {
        this.setStepToReview(LiaStatus.AcademicTitle);
        return this.liaAcademicTitleService.createOrUpdateLiaAcademicTitle(input).pipe(tap((output: CreateOrUpdateLiaAcademicTitleOutput) => {
            this.lia.liaAcademicTitles.titles = output.liaAcademicTitles;
            this.lia.currentStep = output.status;
        })).pipe(map(() => null));
    }

    deleteLiaAcademicTitle(guid: string): Observable<void> {
        return this.liaAcademicTitleService.deleteLiaAcademicTitle(this.caseId, guid);
    }

    getLiaAcademicTitleFiles(forceRefresh?: boolean, caseId = this._caseId) : Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.liaAcademicTitleService.getLiaAcademicTitleFiles(caseId).pipe(tap((output: LiaDocumentsOutput) => {
                this.lia.liaAcademicTitles.documents = output.liaDocuments;
            })).pipe(map((output: LiaDocumentsOutput) => output.liaDocuments));
        }
        return of(this.lia.liaAcademicTitles.documents);
    }

    getLiaAcademicTitleFile(fileId: number): Observable<GeneralFileDto> {
        return this.liaAcademicTitleService.getFile(this._caseId, fileId)
    }

    createBlankLiaAcademicTitle(): Observable<string> {
        return this.liaAcademicTitleService.createBlankLiaAcademicTitle(this.caseId);
    }

    uploadLiaAcademicTitleFiles(liaAcademicTitleId: string, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.liaAcademicTitleService.uploadFiles(liaAcademicTitleId, input);
    }

    deleteLiaAcademicTitleFile(fileId: number): Observable<void> {
        return this.liaAcademicTitleService.deleteFile(this._caseId, fileId);
    }

    getLiaEmploymentB(): Observable<LiaEmploymentBDto> {
        return of(this.lia.liaEmploymentB);
    }

    getLiaEmploymentC(): Observable<LiaEmploymentCDto> {
        return of(this.lia.liaEmploymentC);
    }

    getLiaEmploymentCSync(): LiaEmploymentCDto {
        return this.lia.liaEmploymentC;
    }

    getLiaEmploymentCMobileFiles(caseId = this._caseId) : Observable<GeneralDocumentGroupDto[]> {
        return this.liaEmploymentCService.getLiaEmploymentCMobileFiles(caseId).pipe(map((output: LiaDocumentsOutput) => output.liaDocuments));
    }

    getLiaEmploymentCRecordFiles(id: string, employmentType: string, caseId = this._caseId) : Observable<GeneralDocumentGroupDto[]> {
        return this.liaEmploymentCService.getLiaEmploymentCRecordFiles(caseId, id, employmentType).pipe(map((output: LiaDocumentsOutput) => output.liaDocuments));
    }

    getLiaEmploymentBRecordFiles(forceRefresh?: boolean, caseId = this._caseId) : Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.liaEmploymentBService.getLiaEmploymentBRecordFiles(caseId).pipe(tap((output: LiaDocumentsOutput) => {
                this.lia.liaEmploymentB.employmentRecordDocuments = output.liaDocuments;
            })).pipe(map((output: LiaDocumentsOutput) => output.liaDocuments));
        }
        return of(this.lia.liaEmploymentB.employmentRecordDocuments);
    }

    uploadLiaEmploymentBFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.liaEmploymentBService.uploadFiles(input);
    }

    getLiaEmploymentBRecordFile(fileId: number): Observable<GeneralFileDto> {
        return this.liaEmploymentBService.getRecordFile(this._caseId, fileId);
    }

    getLiaEmploymentCFile(fileId: number): Observable<GeneralFileDto> {
        return this.liaEmploymentCService.getFile(this._caseId, fileId);
    }

    createOrUpdateLiaEmploymentB(input: CreateOrUpdateLiaEmploymentBInput): Observable<void> {
        this.setStepToReview(LiaStatus.EmploymentB);
        return this.liaEmploymentBService.createOrUpdateLiaEmploymentsB(input).pipe(tap((status: LiaStatus) => {
            this.lia.liaEmploymentB = input.liaEmploymentB;
            this.lia.currentStep = status;
        })).pipe(map(() => null));
    }

    createOrUpdateLiaEmploymentC(input: CreateOrUpdateLiaEmploymentCInput): Observable<void> {
        this.setStepToReview(LiaStatus.EmploymentC);
        return this.liaEmploymentCService.createOrUpdateLiaEmploymentsC(input).pipe(tap((output: CreateOrUpdateLiaEmploymentCOutput) => {
            this.lia.liaEmploymentC = input.liaEmploymentC;
            this.lia.currentStep = output.status;
        })).pipe(map(() => null));
    }

    createOrUpdateLiaEmploymentCInWaitingStatus(input: CreateOrUpdateLiaEmploymentCInput): Observable<void> {
        return this.liaEmploymentCService.createOrUpdateLiaEmploymentsWhileWaitingStatus(input).pipe(tap((output: CreateOrUpdateLiaEmploymentCInWaitingStatusOutput) => {
            if (output.isNewInvoiceAddressRequired) {
                this.lia.liaEmploymentC = input.liaEmploymentC;
                this.lia.liaEmploymentC.mobileEmployment = output.mobileEmployment;
                this.router.navigate([`${AppConsts.applicantBaseUrl}/lia/${this._caseId}/wizard/invoice-address`]);
            } else {
                this.router.navigate([`${AppConsts.applicantBaseUrl}/ehc/${output.ehcCaseId}/wizard/employment`]);
            }
        })).pipe(map(() => null));
    }

    getLiaEmploymentBFiles(forceRefresh?: boolean, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.liaEmploymentBService.getLiaEmploymentBFiles(caseId).pipe(tap((output: LiaDocumentsOutput) => {
                this.lia.liaEmploymentB.employmentDocuments = output.liaDocuments;
            })).pipe(map((output: LiaDocumentsOutput) => output.liaDocuments));
        }
        return of(this.lia.liaEmploymentB.employmentDocuments);
    }

    getLiaEmploymentBFile(fileId: number): Observable<GeneralFileDto> {
        return this.liaEmploymentBService.getFile(this._caseId, fileId);
    }

    deleteLiaEmploymentBFile(fileId: number): Observable<void> {
        return this.liaEmploymentBService.deleteFile(this._caseId, fileId);
    }

    deleteLiaEmploymentBRecord(guid: string): Observable<void> {
        return this.liaEmploymentBService.deleteLiaEmploymentBRecord(this.caseId, guid);
    }

    deleteLiaEmploymentCRecord(guid: string): Observable<void> {
        return this.liaEmploymentCService.deleteLiaEmploymentCRecord(this.caseId, guid);
    }

    createBlankLiaEmploymentBRecord(): Observable<string> {
        return this.liaEmploymentBService.createBlankLiaEmploymentBRecord(this.caseId);
    }

    createBlankLiaEmploymentCRecord(): Observable<string> {
        return this.liaEmploymentCService.createBlankLiaEmploymentCRecord(this.caseId);
    }

    uploadLiaEmploymentCMobileFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.liaEmploymentCService.uploadFiles(input);
    }

    uploadLiaEmploymentBRecordFiles(recordId: string, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.liaEmploymentBService.uploadRecordFiles(recordId, input);
    }

    uploadLiaEmploymentCRecordFiles(recordId: string, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.liaEmploymentCService.uploadRecordFiles(recordId, input);
    }

    deleteLiaEmploymentBRecordFile(fileId: number): Observable<void> {
        return this.liaEmploymentBService.deleteRecordFile(this._caseId, fileId);
    }

    deleteLiaEmploymentCFile(fileId: number): Observable<void> {
        return this.liaEmploymentCService.deleteFile(this._caseId, fileId);
    }

    getLiaGuaranteeFiles(forceRefresh?: boolean, caseId = this._caseId) : Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.liaGuaranteeService.getLiaGuaranteeFiles(caseId).pipe(tap((output: LiaDocumentsOutput) => {
                this.lia.liaGuaranteeOutput.documents = output.liaDocuments;
            })).pipe(map((output: LiaDocumentsOutput) => output.liaDocuments));
        }
        return of(this.lia.liaGuaranteeOutput.documents);
    }

    liaGuaranteeProceed(): Observable<void> {
        this.setStepToReview(LiaStatus.Guarantee);
        return this.liaGuaranteeService.proceed(this.caseId).pipe(tap((status: LiaStatus) => {
            this.lia.currentStep = status;
        })).pipe(map(() => null));
    }

    getLiaGuaranteeFile(fileId: number): Observable<GeneralFileDto> {
        return this.liaGuaranteeService.getFile(this._caseId, fileId)
    }

    uploadLiaGuaranteeFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.liaGuaranteeService.uploadFiles(input);
    }

    deleteLiaGuaranteeFile(fileId: number): Observable<void> {
        return this.liaGuaranteeService.deleteFile(this._caseId, fileId);
    }

    getLiaPostgraduateTitlePrivateLaws(): Observable<LiaPostgraduateTitlePrivateLawDto[]> {
        return of(this.lia.liaPostgraduateTitlePrivateOutput.liaPostgraduateTitlePrivateLaws);
    }

    createOrUpdateLiaPostgraduateTitlePrivateLaw(input: CreateOrUpdateLiaPostgraduateTitlePrivateLawInput): Observable<void> {
        this.setStepToReview(LiaStatus.PostgraduateTitlePrivateLaw);
        return this.liaPostgraduateTitlePrivateLawService.createOrUpdateLiaPostgraduateTitlePrivateLaw(input).pipe(tap((output: CreateOrUpdateLiaPostgraduateTitlePrivateLawOutput) => {
            this.lia.liaPostgraduateTitlePrivateOutput = output;
            this.lia.currentStep = output.status;
        })).pipe(map(() => null));
    }

    deleteLiaPostgraduateTitlePrivateLaw(guid: string): Observable<void> {
        return this.liaPostgraduateTitlePrivateLawService.deletePrivateLiaPostgraduateTitlePrivateLaw(this.caseId, guid);
    }

    createBlankLiaPostgraduateTitlePrivateLaw(): Observable<string> {
        return this.liaPostgraduateTitlePrivateLawService.createBlankLiaPostgraduateTitlePrivateLaw(this.caseId);
    }

    getLiaPostgraduateTitles(): Observable<LiaPostgraduateTitleDto[]> {
        return of(this.lia.liaPostgraduateTitleOutput.liaPostgraduateTitles);
    }

    createOrUpdateLiaPostgraduateTitles(input: CreateOrUpdateLiaPostgraduateTitleInput): Observable<void> {
        this.setStepToReview(LiaStatus.PostgraduateTitle);
        return this.liaPostgraduateTitleService.createOrUpdateLiaPostgraduateTitles(input).pipe(tap((output: CreateOrUpdateLiaPostgraduateTitleOutput) => {
            this.lia.liaPostgraduateTitleOutput = output;
            this.lia.currentStep = output.status;
        })).pipe(map(() => null));
    }

    createOrUpdateLiaRestrictionB(input: CreateOrUpdateLiaRestrictionBInput) : Observable<void> {
        this.setStepToReview(LiaStatus.RestrictionB);
        return this.liaRestrictionBService.createOrUpdateLiaRestrictionB(input).pipe(tap((status: LiaStatus) => {
            this.lia.liaRestrictionB = input.liaRestrictionB;
            this.lia.currentStep = status;
        })).pipe(map(() => null));
    }

    getLiaRestrictionB(): Observable<LiaRestrictionBDto>
    {
        return of(this.lia.liaRestrictionB);
    }

    getRestrictionBFiles(forceRefresh?: boolean, areProceedingsDocumentsRequired: boolean = false, areForeignDocumentsRequired: boolean = false, caseId = this._caseId, ): Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.liaRestrictionBService.getLiaRestrictionBDocuments(caseId, areProceedingsDocumentsRequired, areForeignDocumentsRequired).pipe(tap((output: LiaDocumentsOutput) => {
                this.lia.liaRestrictionB.restrictionBDocuments = output.liaDocuments;
            })).pipe(map((output: LiaDocumentsOutput) => output.liaDocuments));
        }
        return of(this.lia.liaRestrictionB.restrictionBDocuments);
    }

    getAndUpdateLicenceInAnotherCantonStatus(): Observable<LiaLicenceInAnotherCantonCacheDto[]> {
        return this.liaRestrictionBService.getAndUpdateLicenceInAnotherCantonStatus(this.caseId);
    }

    getRestrictionBFile(fileId: number): Observable<GeneralFileDto> {
        return this.liaRestrictionBService.getFile(this._caseId, fileId)
    }

    uploadRestrictionBFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.liaRestrictionBService.uploadFiles(input);
    }

    deleteRestrictionBFile(fileId: number): Observable<void> {
        return this.liaRestrictionBService.deleteFile(this._caseId, fileId);
    }

    getLiaPreviousEmployment(): Observable<LiaPreviousEmploymentDto> {
        return of(this.lia.liaPreviousEmployment);
    }

    createOrUpdateLiaPreviousEmployment(input: CreateOrUpdateLiaPreviousEmploymentInput): Observable<void> {
        this.setStepToReview(LiaStatus.PreviousEmployment);
        return this.liaPreviousEmploymentService.createOrUpdateLiaPreviousEmployment(input).pipe(tap((status: LiaStatus) => {
            this.lia.liaPreviousEmployment = input.liaPreviousEmployment;
            this.lia.currentStep = status;
        })).pipe(map(() => null));
    }

    deleteLiaPreviousEmploymentRecord(guid: string): Observable<void> {
        return this.liaPreviousEmploymentService.deleteLiaPreviousEmploymentRecord(this.caseId, guid);
    }

    createBlankLiaPreviousEmploymentRecord(): Observable<string> {
        return this.liaPreviousEmploymentService.createBlankLiaPreviousEmploymentRecord(this.caseId);
    }

    getLiaPreviousEmploymentRecordFiles(forceRefresh?: boolean, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.liaPreviousEmploymentService.getLiaPreviousEmploymentRecordFiles(caseId).pipe(tap((output: LiaDocumentsOutput) => {
                this.lia.liaPreviousEmployment.previousEmploymentRecordDocuments = output.liaDocuments;
            })).pipe(map((output: LiaDocumentsOutput) => output.liaDocuments));
        }
        return of(this.lia.liaPreviousEmployment.previousEmploymentRecordDocuments);
    }

    getLiaPreviousEmploymentRecordFile(fileId: number): Observable<GeneralFileDto> {
        return this.liaPreviousEmploymentService.getRecordFile(this._caseId, fileId)
    }

    deleteLiaPreviousEmploymentRecordFile(fileId: number): Observable<void> {
        return this.liaPreviousEmploymentService.deleteRecordFile(this._caseId, fileId);
    }

    uploadLiaPreviousEmploymentRecordFile(recordId: string, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.liaPreviousEmploymentService.uploadRecordFiles(recordId, input);
    }

    createOrUpdateLiaLanguageSkillB(input: CreateOrUpdateLiaLanguageSkillBInput): Observable<void> {
        this.setStepToReview(LiaStatus.LanguageSkillB);
        return this.liaLanguageSkillBService.createOrUpdateLiaLanguageSkillB(input).pipe(tap((status: LiaStatus) => {
            this.lia.liaLanguageSkillB = input.liaLanguageSkillB;
            this.lia.currentStep = status;
        })).pipe(map((status: LiaStatus) => null));
    }

    getLiaLanguageSkillB(): Observable<LiaLanguageSkillBDto> {
        return of(this.lia.liaLanguageSkillB);
    }

    getLanguageSkillBFiles(forceRefresh?: boolean, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.liaLanguageSkillBService.getLiaLanguageSkillBDocuments(caseId).pipe(tap((output: LiaDocumentsOutput) => {
                this.lia.liaLanguageSkillB.languageSkillBDocuments = output.liaDocuments;
            })).pipe(map((output: LiaDocumentsOutput) => output.liaDocuments));
        }
        return of(this.lia.liaLanguageSkillB.languageSkillBDocuments);
    }

    getLanguageSkillBFile(fileId: number): Observable<GeneralFileDto> {
        return this.liaLanguageSkillBService.getFile(this._caseId, fileId)
    }

    uploadLanguageSkillBFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.liaLanguageSkillBService.uploadFiles(input);
    }

    deleteLanguageSkillBFile(fileId: number): Observable<void> {
        return this.liaLanguageSkillBService.deleteFile(this._caseId, fileId);
    }

    createOrUpdateLicenceInAnotherCanton(input: CreateOrUpdateLiaLicenceInAnotherCantonInput): Observable<void> {
        this.setStepToReview(LiaStatus.LicenceInAnotherCanton);
        return this.liaLicenceInAnotherCantonService.createOrUpdateLiaLicenceInAnotherCanton(input).pipe(tap((status: LiaStatus) => {
            this.lia.liaLicenceInAnotherCanton = input.liaLicenceInAnotherCanton;
            this.lia.currentStep = status;
        })).pipe(map(() => null));
    }

    getLicenceInAnotherCanton(): Observable<LiaLicenceInAnotherCantonDto> {
        return of(this.lia.liaLicenceInAnotherCanton);
    }

    getVaccination(): Observable<LiaVaccinationDto> {
        return of(this.lia.liaVaccination.vaccination);
    }

    createOrupdateVaccination(input: LiaVaccinationDto): Observable<LiaStatus> {
        this.setStepToReview(LiaStatus.Vaccination);
        const dto = new CreateOrUpdateLiaVaccinationInputDto({ caseId: this._caseId, liaVaccination: input });
        return this.liaVaccinationServiceProxy.createOrUpdateLiaVaccination(dto).pipe(tap((status: LiaStatus) => {
            this.lia.liaVaccination.vaccination = input
            this.lia.currentStep = status;
        }));
    }

    getVaccinationFiles(forceRefresh: boolean, requestVaccinationLicenceCodeId: string): Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.liaVaccinationServiceProxy.getFiles(this._caseId, requestVaccinationLicenceCodeId).pipe(tap((output: LiaDocumentsOutput) => {
                this.lia.liaVaccination.vaccinationDocuments = output.liaDocuments;
            })).pipe(map((output: LiaDocumentsOutput) => output.liaDocuments));
        }

        return of(this.lia.liaVaccination.vaccinationDocuments);
    }

    getVaccinationFile(fileId: number): Observable<GeneralFileDto> {
        return this.liaVaccinationServiceProxy.getFile(this._caseId, fileId)
    }

    uploadVaccinationFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.liaVaccinationServiceProxy.uploadFiles(input);
    }

    deleteVaccinationFile(fileId: number): Observable<void> {
        return this.liaVaccinationServiceProxy.deleteFile(this._caseId, fileId);
    }

    getUrlForStep(step: number): string {
        switch (step) {
            case LiaStatus.Person:
                return 'person';
            case LiaStatus.Contact:
                return 'contact';
            case LiaStatus.Restriction:
                return 'restriction';
            case LiaStatus.RestrictionB:
                return 'restriction-b';
            case LiaStatus.LanguageSkill:
                return 'language-skills';
            case LiaStatus.LanguageSkillB:
                return 'language-skills-b';
            case LiaStatus.Insurance:
                return 'insurance';
            case LiaStatus.Employment:
                return 'employment';
            case LiaStatus.EmploymentB:
                return 'employment-b';
            case LiaStatus.EmploymentC:
                return 'employment-c';
            case LiaStatus.PreviousEmployment:
                return 'previous-employment';
            case LiaStatus.Guarantee:
                return 'guarantee';
            case LiaStatus.InvoiceAddress:
                return 'invoice-address';
            case LiaStatus.Document:
                return 'document';
            case LiaStatus.AcademicTitle:
                return 'academic-title';
            case LiaStatus.PostgraduateTitlePrivateLaw:
                return 'postgraduate-title-private-law';
            case LiaStatus.Diploma:
                return 'diploma';
            case LiaStatus.PostgraduateTitle:
                return 'postgraduate-title';
            case LiaStatus.LicenceInAnotherCanton:
                return 'licence-in-another-canton';
            case LiaStatus.Vaccination:
                return 'vaccination';
            case LiaStatus.Release:
                return 'release';
            case LiaStatus.FinishReview:
                return 'finish-review';
            default:
                console.error('Invalid status for getUrlForStep');
                return null;
        }
    }

    isEligibleForEhc(): Observable<boolean> {
        return this.liaTypeService.isEligibleForEhc(this.lia.professionId);
    }
}
