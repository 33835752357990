import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationService } from '@app/shared/application/application.service';
import { LegService } from './leg.service';

@Injectable()
export class LegGuard implements CanActivate {

    constructor(
        private legService: LegService,
        private applicationService: ApplicationService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
       return this.legService.getLeg(this.applicationService.caseId).pipe(map(() => true));
    }
}
