import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import {
    PersonChangeRequestServiceProxy,
    GetPersonChangeRequestsOutput,
    IdentificationServiceProxy,
    MySironaServiceProxy,
    UserContactDto,
    CreateOrUpdateEmploymentChangeRequestInput,
    GetEmploymentsOutput,
    UserEmploymentDto,
    IdentifyUserInput,
    UserIdentificationDto,
    GeneralFileDto,
    IdentificationStatusDto,
    PersonChangeRequestDto,
    CreatePersonChangeRequestDto,
    VerifyUserNameChangeInput,
    GetExistingPersonChangeRequestInfoOutput,
    GetExistingEmploymentChangeRequestInfoOutput,
    EmploymentChangeRequestDto,
    UserIdentificationFilesUploadInput,
    MultipleRegisterPersonDto,
    GeneralFileInfoDto,
    GeneralDocumentGroupDto,
    ApplicationUploadFilesInput,
    UserPersonLicenceDto,
    GetPersonLicencesOutput,
    PersonLicenceType,
    UserServiceProxy,
    UserEditDto,
    UserShowDto,
    ContactDto,
    PersonWithContactDto,
    UpdateUserInfoDto
} from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConsts } from '@shared/AppConsts';
import { DateTime } from 'luxon';
import { MySironaLegitimationService } from './my-sirona-legitimation.service';

@Injectable()
export class UserService extends AppComponentBase {
    identificationStatus: IdentificationStatusDto;
    user: UserContactDto;
    employments: UserEmploymentDto[] = [];
    professionalLicences: UserPersonLicenceDto[] = [];
    expenseHealthCarePersonalLicences: UserPersonLicenceDto[] = [];
    userIdentificationDocuments: GeneralDocumentGroupDto[];

    constructor(
        injector: Injector,
        private personChangeRequestService: PersonChangeRequestServiceProxy,
        private identificationService: IdentificationServiceProxy,
        private mySironaService: MySironaServiceProxy,
        private legitimationService: MySironaLegitimationService,
        private userServiceProxy: UserServiceProxy
    ) {
        super(injector);
    }

    getUser(): Observable<UserContactDto> {
        return this.mySironaService.getUserContactInfo()
            .pipe(tap((output: UserContactDto) => {
                this.user = output;
            }));
    };

    updateUserInfo(dto: UpdateUserInfoDto) {
        return this.mySironaService.updateUserInfo(dto);
    };

    getUserById(id: number): Observable<UserShowDto> {
        return this.userServiceProxy.getUserForShow(id).pipe(
            map(userForShow => userForShow.user));
    }

    uploadIdentificationFiles(input: UserIdentificationFilesUploadInput): Observable<void> {
        return this.identificationService.uploadIdentificationFiles(input).pipe(tap(() => {
            this.user.userIdentificationStatus.identificationStatus = AppConsts.codes.identificationStatus.submitted;
        }));
    }

    deleteIdentificationFile(): Observable<void> {
        return this.identificationService.deleteIdentification().pipe(tap(() => {
            this.user.userIdentificationStatus.identificationStatus = AppConsts.codes.identificationStatus.none;
        }));
    }

    public GetPersonContact(personId: number): Observable<PersonWithContactDto> {
        return this.mySironaService.getPersonContact(personId);
    }

    getPersonLicenceFile(id: number, personId: number): Observable<GeneralFileDto> {
        return this.mySironaService.getPersonLicenceFile(id, personId);
    }

    getMyIdentificationFile(docId: number): Observable<GeneralFileDto> {
        return this.identificationService.getMyIdentificationFile(docId);
    }

    getIdentificationFile(userIdentificationId: number, docId: number): Observable<GeneralFileDto> {
        return this.identificationService.getIdentificationFile(userIdentificationId, docId);
    }

    getIdentification(userIdentificationId: number): Observable<UserIdentificationDto> {
        return this.identificationService.getIdentification(userIdentificationId);
    }

    getUserIdentificationGln(): Observable<string> {
        return this.identificationService.getUserIdentificationGln();
    }

    getIdentifications(filter: string, sorting: string, maxResultCount: number, skipCount: number) {
        return this.identificationService.getIdentifications(filter, sorting, maxResultCount, skipCount);
    }

    identifyUser(input: IdentifyUserInput): Observable<void> {
        return this.identificationService.identifyUser(input);
    }

    createPersonChangeRequest(personId: number, input: CreatePersonChangeRequestDto): Observable<void> {
        return this.personChangeRequestService.createPersonChangeRequest(personId, input);
    }

    createEmploymentChangeRequest(input: CreateOrUpdateEmploymentChangeRequestInput): Observable<void> {
        return this.personChangeRequestService.createEmploymentChangeRequest(input);
    }

    getPersonChangeRequests(filter: string, sorting: string, maxResultCount: number, skipCount: number): Observable<GetPersonChangeRequestsOutput> {
       return this.personChangeRequestService.getPersonChangeRequests(filter, sorting, maxResultCount, skipCount);
    }

    getPersonChangeRequest(id: number): Observable<PersonChangeRequestDto> {
        return this.personChangeRequestService.getPersonChangeRequest(id);
    }

    getIdentificationStatus(userId: number = null, isAuthority: boolean = false, refresh = false): Observable<IdentificationStatusDto> {
        if (!refresh && this.identificationStatus) {
            return of(this.identificationStatus);
        }
        if (isAuthority){
            return this.identificationService.getIdentificationStatus(userId)
                .pipe(tap(output =>
                this.identificationStatus = output.identificationStatus
            ), map((output) => output.identificationStatus));
        } else {
            return this.mySironaService.getIdentificationStatus()
                .pipe(tap(output =>
                this.identificationStatus = output.identificationStatus
            ), map((output) => output.identificationStatus));
        }
    }

    verifyUserNameChange(input: VerifyUserNameChangeInput): Observable<void> {
        return this.identificationService.verifyPersonNameChange(input);
    }

    getEmployments(): Observable<UserEmploymentDto[]> {
        return this.mySironaService.getEmployments(this.legitimationService.GetPersonId())
            .pipe(tap((output: GetEmploymentsOutput) => {
                this.employments = output.items;
            }), map((output: GetEmploymentsOutput) => output.items));
    };

    getSponsorshipCount(): Observable<number> {
        return this.mySironaService.getSponsorshipCount().pipe(
                map(sponsorships => sponsorships.totalCount));
    }

    getEmploymentsCount(onlyActive: boolean): Observable<number> {
        return this.mySironaService.getEmploymentsCount(this.legitimationService.GetPersonId()).pipe(
                map(employments => onlyActive ? employments.activeCount : employments.totalCount));
    }

    getPersonLicences(): Observable<UserPersonLicenceDto[]> {
        return this.mySironaService.getPersonLicences(this.legitimationService.GetPersonId(), [])
            .pipe(tap((output: GetPersonLicencesOutput) => {
                this.professionalLicences = output.items;
            }), map((output: GetPersonLicencesOutput) => output.items));
    }

    getProfessionalPersonLicences(): Observable<UserPersonLicenceDto[]> {
        return this.mySironaService.getPersonLicences(this.legitimationService.GetPersonId(), [PersonLicenceType.ProfessionalLicence, PersonLicenceType.ProfessionalNinetyDays])
            .pipe(tap((output: GetPersonLicencesOutput) => {
                this.professionalLicences = output.items;
            }), map((output: GetPersonLicencesOutput) => output.items));
    }

    getExpenseHealthCarePersonLicence(): Observable<UserPersonLicenceDto[]> {
        return this.mySironaService.getPersonLicences(this.legitimationService.GetPersonId(), [PersonLicenceType.ExpenceHealthCareLicence, PersonLicenceType.ExpenceHealthCareNinetyDays])
            .pipe(tap((output: GetPersonLicencesOutput) => {
                this.expenseHealthCarePersonalLicences = output.items;
            }), map((output: GetPersonLicencesOutput) => output.items));
    }

    getLicencesCount(onlyActive: boolean): Observable<number> {
        return this.mySironaService.getPersonLicencesCount(this.legitimationService.GetPersonId()).pipe(
            map(lic => onlyActive ? lic.activeCount : lic.totalCount));
    }

    getSubmittedEmploymentChangeRequests(): Observable<EmploymentChangeRequestDto[]> {
        return this.mySironaService.getSubmittedEmploymentChangeRequests(this.legitimationService.GetPersonId());
    }

    getExistingEmploymentChangeRequestInfo(): Observable<GetExistingEmploymentChangeRequestInfoOutput> {
        return this.mySironaService.getExistingEmploymentChangeRequestInfo(this.legitimationService.GetPersonId());
    }

    getExistingPersonChangeRequestInfo(): Observable<GetExistingPersonChangeRequestInfoOutput> {
        return this.mySironaService.getExistingPersonChangeRequestInfo(this.legitimationService.GetPersonId());
    }

    hasAnyApplicationOrSponsorship(): Observable<boolean> {
        if (abp.auth.isGranted('Pages.Applicant')) {
            return this.mySironaService.hasAnyApplicationOrSponsorship();
        }

        return of(false);
    }

    isEmailConfirmed(): Observable<boolean> {
        if (this.user) {
            return of(this.user.isEmailConfirmed);
        }

        return this.mySironaService.isUserEmailConfirmed();
    }

    validateGlnForIdentification(gln: string): Observable<MultipleRegisterPersonDto> {
        return this.identificationService.validateGlnForIdentification(gln);
    }

    getUserIdentificationFiles(forceRefresh?: boolean): Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.identificationService.getUserIdentificationDocuments().pipe(tap((output: GeneralDocumentGroupDto[]) => {
                this.userIdentificationDocuments = output;
            })).pipe(map((output: GeneralDocumentGroupDto[]) => output));
        }
        return of(this.userIdentificationDocuments);
    }

    getUserIdentificationFile(fileId: number): Observable<GeneralFileDto> {
        return this.identificationService.getFile(fileId);
    }

    submitRegisterIdentification(): Observable<void> {
        return this.identificationService.submitRegisterIdentification();
    }

    uploadUserIdentificationFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.identificationService.uploadFiles(input);
    }

    deleteUserIdentificationFile(fileId: number): Observable<void> {
        return this.identificationService.deleteFile(fileId);
    }
}
